
.projects-container {
    padding-right: 1.5em;
    grid-area: projects;
    border-right: 7px solid #fafafa;
    text-align: right;
    height: 100vh;
    padding: 2em 0em;
    position: fixed;
    text-align: center;
    width: 20%;
    box-sizing: border-box;
}

.projects-inner-container {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.projects-title {
    font-weight: 500;
    letter-spacing: 3px;
    display: inline-block;
    padding: 0 1em;
    color: #4c4c4c;
    text-transform: uppercase;
}

.projects-list {
    list-style: none;
    display: inline-block;
    text-align: left;
    padding: 0;
    margin-top: 0;
    width: 100%;
}

.project-name:nth-child(1) {
    border-top: 4px solid #fafafa;
}

.project-name {
    padding: .8em 0;
    padding-left: 2em;
    letter-spacing: 3px;
    cursor: pointer;
    color: #7a7a7a7a;
    font-weight: 600;
    text-transform: capitalize;
    border-bottom: 4px solid #fafafa;
}

.show-projects-mobile .project-name {
    padding: .8em 0;
    padding-left: 0;
    text-align: left;
    letter-spacing: 3px;
    cursor: pointer;
    color: #7a7a7a7a;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: none;
}

.show-projects-mobile .project-name:nth-child(1) {
    border-top: none;
}

.show-projects-mobile .projects-list {
    list-style: none;
    display: inline-block;
    text-align: left;
    padding: 0;
    margin-top: 0;
    display: inline-block;
    width: auto;
}


.project-name:hover {
    color: #818181;
}

.active-project, .active-project:hover {
    color: #197afb;
}

.add-project-input {
    border: none;
    box-shadow: 0px 0px 3px 0px #b9b9b9;
    width: 75%;
    border-radius: 13px;
    font-weight: 300;
    box-sizing: border-box;
    font-size: 18px;
    padding: 7px 4px;
    padding-left: 16px;
    color: #333;
    outline: none;
}

.add-project-input-error {
    /* border: none; */
    box-shadow: 0px 0px 3px 0px #ff7597;
    /* width: 100%;
    border-radius: 5px;
    font-weight: 300;
    box-sizing: border-box;
    font-size: 18px;
    padding: 6px 4px;
    padding-left: 16px;
    color: #333;
    outline: none; */
}

.add-project-input:focus {
    box-shadow: 0px 0px 3px 0px #74c393;
}

.add-project-btn {
    margin-top: 1em;
    font-size: 2em;
    border-radius: 50%;
    font-weight: bold;
    width: 50px;
    height: 50px;
    line-height: 25px;
    border: none;
    box-shadow: 0px 1px 2px 0px #cacaca;
    cursor: pointer;
    color: #555;
    background: white;
    outline: none;
}

.add-project-btn:hover {
    box-shadow: 0px 1px 2px 0px #999;
    color: #ff6767;
}

