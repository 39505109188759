.landing_page_container {
  display: grid;
  grid-template-columns: 4fr 6.4fr;
  grid-template-rows: 100px 2fr 1fr 100px;
  font-family: Roboto;
}

.lp_outer_container {
  width: 100%;
  min-height: 100vh;
}

.lp_illustration_container {
  width: 95%;
  margin: 0 auto;
  grid-column-start: 2;
  grid-row-start: 2;
}

.lp_illustration {
  max-width: 100%;
}

.lp_info_container {
  margin: auto 10px;
  padding: 1em 2em 1em 2em;
  font-size: 1.5em;
  font-family: Roboto;
  color: #fff;
  grid-column-start: 1;
  grid-row-start: 2;
  background: #6666660d;
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px #00000010;
}

.lp_info_container:hover {
  /* background: #3f3f3f0d; */
  box-shadow: 0px 0px 4px 0px #00000021;
}

.lp_info_header {
  font-weight: 400;
}

.lp_info_text {
  font-size: .95em;
  font-weight: 400;
  word-spacing: 2px;
  line-height: 35px;
}

.lp_footer {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 1em;
  padding-left: 4em;
  font-size: 1.5em;
  color: white;
}


/* .lp_cta {
  padding: 1em;
  display: inline-block;
  border-radius: 25px;
  background: white;
  color: #ff6d4e;
  cursor: pointer;
  box-shadow: 1px 1px 3px 0px #e0e0e0;
  text-transform: uppercase;
  font-weight: bold;
} */
/* 
.lp_cta:hover {
  background: #ff6d4e;
  color: white;
  box-shadow: 0px 0px 3px 0px rgb(201, 201, 201);
} */

.firebaseui-idp-button {
  border-radius: 15px!important;
  /* background: rgba(255, 255, 255, 0.418)!important; */
}

.firebaseui-idp-button:hover {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.199);
}

.firebaseui-container, .firebaseui-idp-list {
  display: inline-block;
  text-align: right;
}

.firebaseui-idp-list {
  display: inline-block;
}

.firebaseui-card-content {
  text-align: right;
}

.firebaseui-page-provider-sign-in {
  background: inherit;
  display: inline;
}

.firebaseui-idp-google>.firebaseui-idp-text {
  color: #757575;
  font-size: 1.25em;
}

@media only screen and (max-width: 1175px) {
  .landing_page_container {
    grid-template-rows: 100px 2fr 100px;
  }

  .lp_info_container {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .lp_illustration_container {
    display: none;
  }

  .lp_footer {
    grid-row-start: 3;
    font-size: 1em;
    text-align: center;
  }

  .logo_lp {
    width: 120px;
    padding: 2em 0;
  }
}

/* .firebaseui-idp-button:hover {
  background: #ff6d4e;
} */