.add-task-container {
    margin-top: 4em;
    margin-bottom: 5em;
    text-align: left;
    padding-left: 2.2em;
    height: 137px;
    box-sizing: border-box;
    /* position: fixed; */
    width: 100%;
    background: #fff;
    z-index: 1;
}

.date-picker-container:first-child {
    display: inline-block;
    margin-right: 10px;
}

.calendar-icon {
    width: 30px;
    fill: #cacaca;
    cursor: pointer;
    vertical-align: sub;
    margin-right: 3px;
    outline: none;
}

.calendar-icon:hover {
    fill: #209fda;
}

.calendar-set {
    width: 30px;
    fill: #209fda;
    cursor: pointer;
    vertical-align: sub;
    margin-right: 15px;
    outline: none;
}


.react-datepicker-wrapper input{
    font-size: 14px;
    font-weight: 300;
    background: white;
    outline: none;
    cursor: pointer;
    border: none;
    padding: .5em;
    border-radius: 8px;
    box-shadow: -1px 0px 2px 2px #e6e6e6;
}

.priority-icon-main, .priority-icon {
    width: 30px;
    fill: #cacaca;
    cursor: pointer;
    vertical-align: sub;
    margin-right: 15px;
    outline: none;
}

.priority-icon-main:hover {
    fill: #209fda;
}

.priority-levels {
    display: none;
    vertical-align: top;
    margin-left: -55px;
}

.low, .low-priority:hover  {
    fill: #ffefb8;
}

.med, .mid-priority:hover {
    fill: #ffa970;
}

.high, .high-priority:hover {
    fill: #ff6767;
}


.priority-levels-show {
    display: inline-block;
    vertical-align: top;
    margin-left: -45px;
}

.priority-levels-show .priority-icon {
    display: inline-block;
}

.priority-icon, .priority-icon-main {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* Add Task input */

.add-task-input-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-right: 2.2em;
    box-sizing: border-box;
}

.add-task-input {
    font-size: 1.15em;
    /* margin-right: 15px; */
    flex-grow: 1;
    padding: .5em 1em;
    outline: none;
    border: none;
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 1px 1px #eee;
}

.add-task-btn {
    font-size: 1.15em;
    font-weight: bolder;
    background: #ffa563;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    padding: .5em 1em;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0px 0px 0px 1px #ffa563;
}

.add-task-btn:hover {
    background: #ff6664;
    color: white;
}

.send-btn {
    width: 25px;
    fill: #cacaca;
    vertical-align: middle;
    border: 2px solid #dadada;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.send-btn:hover {
    fill: #209fda;
    border: 2px solid #209fda;

}


/* Task Container */

.tasks-container {
    padding-top: 2em;
}

.task-container {
    padding: .1em 0;
    text-align: left;
    box-sizing: border-box;
    border-left: 20px solid #fafafa;
    box-shadow: 1px 0 0 1px #fafafa;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.tasks-container-title {
    margin: 0 auto 1em auto;
    font-weight: 500;
    font-size: 1.5em;
    color: #464646;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.rem-project-container {
    text-align: right;
    padding-right: 2em;
    margin-top: 3em;
    margin-bottom: 2em;
}

.rem-project {
    /* cursor: pointer;
    color: #e91e6254;
    background: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 12px;
    box-shadow: 0px 0px 1px 2px #e91e6254;
    border-radius: 23px; */
    cursor: pointer;
    color: #84848454;
    background: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 12px;
    box-shadow: 0px 0px 1px 2px #a9a9a954;
    border-radius: 23px;
}

.rem-project:hover {
    color: #e91e62ba;
    background: #fff;
    box-shadow: 0px 0px 1px 2px #e91e62ba;
}

.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ababab1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.modal {
    border-radius: 37px;
    background: #fff;
    padding: 2em;
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 1.2em;
    text-align: left;
    max-width: 90%;
    box-shadow: 0px 0px 1px 2px #e91e6221;
    color: #ff578e;
    font-weight: 500;
}

.modal-small-print {
    font-size: 0.7em;
}

.modal-btn-container {
    margin-top: 2em;
    display: flex;
    justify-content: space-around;
}

.modal-btn {
    border-radius: 10px;
    display: inline-block;
    padding: 1em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0px 0px 1px 2px #7d7d7dba;
    cursor: pointer;
}

.confirm-btn {
    color: #7d7d7dba;;
}

.cancel-btn {
    color: #e91e62ba;
    background: #fff;
    box-shadow: 0px 0px 1px 2px #e91e62ba;
}

.confirm-btn:hover {
    color: #fff!important;
    background: #505050ba;
    box-shadow: 0px 0px 1px 0px #505050ba;
}

.cancel-btn:hover {
    color: #fff;
    background: #e91e62ba;
    box-shadow: 0px 0px 1px 2px #e91e62ba;
}

.task-completed {
    border-left-color: #29b65f;
    background: #effff5ab;
}

.task-low-priority {
    border-left-color: #ffefb8;
}

.task-med-priority {
    border-left-color: #ffa970;
}

.task-high-priority {
    border-left-color: #ff6767;
}

.task-title {
    color: #313131;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    line-height: 34px;
    margin: 0;
    padding-left: 1em;
    width: 70%;
    display: inline-block;
    vertical-align: middle;
}

.task-title-calendar-icon {
    width: 17px;
    fill: #6caeda;
    vertical-align: sub;
    margin-right: 10px;
}

.task-container-date {
    font-weight: 500;
    color: #8a8a8a;
    font-size: .85em;
}

.task-date-container {
    padding-top: .3em;
}

.task-icons {
    display: inline-block;
    vertical-align: middle;
}

.svg-icons {
    width: 29px;
    margin: 0 15px;
    fill: #bdbdbd;
    cursor: pointer;
}

.check-svg-completed {
    fill: #29b65f;
}

.check-svg:hover {
    fill: #29b65f;
}

.trash-svg:hover {
    fill: #ee5050;
}

.tasks-filter-container {
    padding: 1em 0 1.5em 3em;
    text-align: left;
    /* border-top: 5px solid #fafafa;
    border-bottom: 5px solid #fafafa; */
}

.tasks-filter {
    display: inline-block;
    padding: .5em .8em;
    margin: 0 1em;
    border-radius: 10px;
    text-transform: uppercase;
    box-shadow: 0px 0px 0px 2px #eee;
    color: #9a9a9a;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

.tasks-filter:hover {
    background: #009688;
    color: white;
    box-shadow: 0px 0px 0px 2px #009688;
}

.tasks-filter::selection {
    background: none;
}

@media only screen and (max-width: 635px) {

    
}


@media only screen and (max-width: 550px) {
    .task-container {
        padding-left: 0;
    }

    .task-title {
        width: 100%;
        box-sizing: border-box;
    }

    .task-icons {
        display: block;
        /* vertical-align: middle; */
        text-align: center;
        padding: 2em 0;
    }

    .task-container {
        padding-left: 0;
        /* flex-wrap: wrap; */
    }

    .task-date-container {
        /* text-align: center; */
    }
}