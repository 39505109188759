@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div::-moz-selection, h2::-moz-selection, h3::-moz-selection, h1::-moz-selection, li::-moz-selection, input::-moz-selection, button::-moz-selection, br::-moz-selection, span::-moz-selection {
    background: none;
}


div::selection, h2::selection, h3::selection, h1::selection, li::selection, input::selection, button::selection, br::selection, span::selection {
    background: none;
}

.main-app {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    grid-template-areas: "projects tasks";
}

.no-content-block {
    padding: 1em;
    margin: 1em;
    box-shadow: 0px 0px 2px 0px #ffcad4d1;
    border-radius: 14px;
    /* letter-spacing: 1px; */
    background: #fff7f86e;
    font-weight: 400;
    color: #9e9e9e;
}

input::-webkit-input-placeholder {
    color: #afafaf;
}

input::-ms-input-placeholder {
    color: #afafaf;
}

input::placeholder {
    color: #afafaf;
}

.tasks-container {
    /* padding-top: 4em; */
    grid-area: tasks;
    text-align: center;
}

.sign-out-button {
    margin-left: auto;
    position: absolute;
    top: 2em;
    right: 3em;
}

.sign-out-icon {
    width: 30px;
    height: 30px;
    fill: #595959;
    cursor: pointer;
}

.sign-out-icon:hover {
    fill: #e91e63;
}

.show-projects-menu {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .projects-container {
        padding-right: 1.5em;
        grid-area: projects;
        border-right: 7px solid #fafafa;
        text-align: right;
        height: 100vh;
        padding: 2em 2em;
        position: fixed;
        width: 25%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 1024px) {
    .main-app {
        grid-template-columns: 1fr 3fr;
        grid-template-areas: "tasks tasks";
        padding-top: 60px;
    }

    .projects-container {
        display: none;
    }

    .show-projects-menu-container {
        position: fixed;
        width: 100%;
        background: white;
        z-index: 9;
        padding: .7em;
        padding-left: 1.2em;
    }

    .show-projects-menu {
        width: 30px;
        /* position: fixed; */
        z-index: 1000;
        top: 2em;
        left: 1.5em;
        background: white;
        /* box-shadow: 0 0 0px 2px #68bcff; */
        border-radius: 5px;
        fill: #2196F3;
        display: block;
    }

    .show-projects-mobile {
        display: block;
        padding-right: 1.5em;
        width: 100%;
        border-right: none;
        text-align: right;
        padding: 0;
        position: fixed;
        box-sizing: border-box;
        z-index: 3;
    }

    .show-projects-mobile .projects-inner-container {
        width: 100%;
        min-height: 100vh;
        background: white;
        padding-bottom: 5em;
        padding-top: 5em;
        max-height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .show-projects-mobile .add-project-input, .add-project-input-error {
        width: 250px;
        max-width: 71%;
    }

    .sign-out-button {
        margin-left: auto;
        position: fixed;
        top: 1em;
        right: 2em;
        z-index: 9;
    }

    .sign-out-icon {
        width: 25px;
        height: 25px;
        fill: #595959;
        cursor: pointer;
    }
}
.logo-container {
  position: absolute;
}

.hide-logo {
  display: none;
}

.logo {
  width: 20%;
  padding: 10px;
  /* transform: rotate(-9deg); */
}

.logo_lp {
  width: 225px;
  padding: 3em 6em;
}

@media only screen and (max-width: 1024px) {
  .logo-container {
    position: absolute;
    width: 200px;
    text-align: center;
    top: 0;
    z-index: 9;
    left: 0;
    right: 0;
    margin: auto;
  }
  .logo {
    -webkit-transform: none;
            transform: none;
  }
}
.landing_page_container {
  display: grid;
  grid-template-columns: 4fr 6.4fr;
  grid-template-rows: 100px 2fr 1fr 100px;
  font-family: Roboto;
}

.lp_outer_container {
  width: 100%;
  min-height: 100vh;
}

.lp_illustration_container {
  width: 95%;
  margin: 0 auto;
  grid-column-start: 2;
  grid-row-start: 2;
}

.lp_illustration {
  max-width: 100%;
}

.lp_info_container {
  margin: auto 10px;
  padding: 1em 2em 1em 2em;
  font-size: 1.5em;
  font-family: Roboto;
  color: #fff;
  grid-column-start: 1;
  grid-row-start: 2;
  background: #6666660d;
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px #00000010;
}

.lp_info_container:hover {
  /* background: #3f3f3f0d; */
  box-shadow: 0px 0px 4px 0px #00000021;
}

.lp_info_header {
  font-weight: 400;
}

.lp_info_text {
  font-size: .95em;
  font-weight: 400;
  word-spacing: 2px;
  line-height: 35px;
}

.lp_footer {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 1em;
  padding-left: 4em;
  font-size: 1.5em;
  color: white;
}


/* .lp_cta {
  padding: 1em;
  display: inline-block;
  border-radius: 25px;
  background: white;
  color: #ff6d4e;
  cursor: pointer;
  box-shadow: 1px 1px 3px 0px #e0e0e0;
  text-transform: uppercase;
  font-weight: bold;
} */
/* 
.lp_cta:hover {
  background: #ff6d4e;
  color: white;
  box-shadow: 0px 0px 3px 0px rgb(201, 201, 201);
} */

.firebaseui-idp-button {
  border-radius: 15px!important;
  /* background: rgba(255, 255, 255, 0.418)!important; */
}

.firebaseui-idp-button:hover {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.199);
}

.firebaseui-container, .firebaseui-idp-list {
  display: inline-block;
  text-align: right;
}

.firebaseui-idp-list {
  display: inline-block;
}

.firebaseui-card-content {
  text-align: right;
}

.firebaseui-page-provider-sign-in {
  background: inherit;
  display: inline;
}

.firebaseui-idp-google>.firebaseui-idp-text {
  color: #757575;
  font-size: 1.25em;
}

@media only screen and (max-width: 1175px) {
  .landing_page_container {
    grid-template-rows: 100px 2fr 100px;
  }

  .lp_info_container {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .lp_illustration_container {
    display: none;
  }

  .lp_footer {
    grid-row-start: 3;
    font-size: 1em;
    text-align: center;
  }

  .logo_lp {
    width: 120px;
    padding: 2em 0;
  }
}

/* .firebaseui-idp-button:hover {
  background: #ff6d4e;
} */

.projects-container {
    padding-right: 1.5em;
    grid-area: projects;
    border-right: 7px solid #fafafa;
    text-align: right;
    height: 100vh;
    padding: 2em 0em;
    position: fixed;
    text-align: center;
    width: 20%;
    box-sizing: border-box;
}

.projects-inner-container {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.projects-title {
    font-weight: 500;
    letter-spacing: 3px;
    display: inline-block;
    padding: 0 1em;
    color: #4c4c4c;
    text-transform: uppercase;
}

.projects-list {
    list-style: none;
    display: inline-block;
    text-align: left;
    padding: 0;
    margin-top: 0;
    width: 100%;
}

.project-name:nth-child(1) {
    border-top: 4px solid #fafafa;
}

.project-name {
    padding: .8em 0;
    padding-left: 2em;
    letter-spacing: 3px;
    cursor: pointer;
    color: #7a7a7a7a;
    font-weight: 600;
    text-transform: capitalize;
    border-bottom: 4px solid #fafafa;
}

.show-projects-mobile .project-name {
    padding: .8em 0;
    padding-left: 0;
    text-align: left;
    letter-spacing: 3px;
    cursor: pointer;
    color: #7a7a7a7a;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: none;
}

.show-projects-mobile .project-name:nth-child(1) {
    border-top: none;
}

.show-projects-mobile .projects-list {
    list-style: none;
    display: inline-block;
    text-align: left;
    padding: 0;
    margin-top: 0;
    display: inline-block;
    width: auto;
}


.project-name:hover {
    color: #818181;
}

.active-project, .active-project:hover {
    color: #197afb;
}

.add-project-input {
    border: none;
    box-shadow: 0px 0px 3px 0px #b9b9b9;
    width: 75%;
    border-radius: 13px;
    font-weight: 300;
    box-sizing: border-box;
    font-size: 18px;
    padding: 7px 4px;
    padding-left: 16px;
    color: #333;
    outline: none;
}

.add-project-input-error {
    /* border: none; */
    box-shadow: 0px 0px 3px 0px #ff7597;
    /* width: 100%;
    border-radius: 5px;
    font-weight: 300;
    box-sizing: border-box;
    font-size: 18px;
    padding: 6px 4px;
    padding-left: 16px;
    color: #333;
    outline: none; */
}

.add-project-input:focus {
    box-shadow: 0px 0px 3px 0px #74c393;
}

.add-project-btn {
    margin-top: 1em;
    font-size: 2em;
    border-radius: 50%;
    font-weight: bold;
    width: 50px;
    height: 50px;
    line-height: 25px;
    border: none;
    box-shadow: 0px 1px 2px 0px #cacaca;
    cursor: pointer;
    color: #555;
    background: white;
    outline: none;
}

.add-project-btn:hover {
    box-shadow: 0px 1px 2px 0px #999;
    color: #ff6767;
}


.add-task-container {
    margin-top: 4em;
    margin-bottom: 5em;
    text-align: left;
    padding-left: 2.2em;
    height: 137px;
    box-sizing: border-box;
    /* position: fixed; */
    width: 100%;
    background: #fff;
    z-index: 1;
}

.date-picker-container:first-child {
    display: inline-block;
    margin-right: 10px;
}

.calendar-icon {
    width: 30px;
    fill: #cacaca;
    cursor: pointer;
    vertical-align: sub;
    margin-right: 3px;
    outline: none;
}

.calendar-icon:hover {
    fill: #209fda;
}

.calendar-set {
    width: 30px;
    fill: #209fda;
    cursor: pointer;
    vertical-align: sub;
    margin-right: 15px;
    outline: none;
}


.react-datepicker-wrapper input{
    font-size: 14px;
    font-weight: 300;
    background: white;
    outline: none;
    cursor: pointer;
    border: none;
    padding: .5em;
    border-radius: 8px;
    box-shadow: -1px 0px 2px 2px #e6e6e6;
}

.priority-icon-main, .priority-icon {
    width: 30px;
    fill: #cacaca;
    cursor: pointer;
    vertical-align: sub;
    margin-right: 15px;
    outline: none;
}

.priority-icon-main:hover {
    fill: #209fda;
}

.priority-levels {
    display: none;
    vertical-align: top;
    margin-left: -55px;
}

.low, .low-priority:hover  {
    fill: #ffefb8;
}

.med, .mid-priority:hover {
    fill: #ffa970;
}

.high, .high-priority:hover {
    fill: #ff6767;
}


.priority-levels-show {
    display: inline-block;
    vertical-align: top;
    margin-left: -45px;
}

.priority-levels-show .priority-icon {
    display: inline-block;
}

.priority-icon, .priority-icon-main {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* Add Task input */

.add-task-input-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-right: 2.2em;
    box-sizing: border-box;
}

.add-task-input {
    font-size: 1.15em;
    /* margin-right: 15px; */
    flex-grow: 1;
    padding: .5em 1em;
    outline: none;
    border: none;
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 1px 1px #eee;
}

.add-task-btn {
    font-size: 1.15em;
    font-weight: bolder;
    background: #ffa563;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    padding: .5em 1em;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0px 0px 0px 1px #ffa563;
}

.add-task-btn:hover {
    background: #ff6664;
    color: white;
}

.send-btn {
    width: 25px;
    fill: #cacaca;
    vertical-align: middle;
    border: 2px solid #dadada;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.send-btn:hover {
    fill: #209fda;
    border: 2px solid #209fda;

}


/* Task Container */

.tasks-container {
    padding-top: 2em;
}

.task-container {
    padding: .1em 0;
    text-align: left;
    box-sizing: border-box;
    border-left: 20px solid #fafafa;
    box-shadow: 1px 0 0 1px #fafafa;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.tasks-container-title {
    margin: 0 auto 1em auto;
    font-weight: 500;
    font-size: 1.5em;
    color: #464646;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.rem-project-container {
    text-align: right;
    padding-right: 2em;
    margin-top: 3em;
    margin-bottom: 2em;
}

.rem-project {
    /* cursor: pointer;
    color: #e91e6254;
    background: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 12px;
    box-shadow: 0px 0px 1px 2px #e91e6254;
    border-radius: 23px; */
    cursor: pointer;
    color: #84848454;
    background: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 12px;
    box-shadow: 0px 0px 1px 2px #a9a9a954;
    border-radius: 23px;
}

.rem-project:hover {
    color: #e91e62ba;
    background: #fff;
    box-shadow: 0px 0px 1px 2px #e91e62ba;
}

.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ababab1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.modal {
    border-radius: 37px;
    background: #fff;
    padding: 2em;
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 1.2em;
    text-align: left;
    max-width: 90%;
    box-shadow: 0px 0px 1px 2px #e91e6221;
    color: #ff578e;
    font-weight: 500;
}

.modal-small-print {
    font-size: 0.7em;
}

.modal-btn-container {
    margin-top: 2em;
    display: flex;
    justify-content: space-around;
}

.modal-btn {
    border-radius: 10px;
    display: inline-block;
    padding: 1em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0px 0px 1px 2px #7d7d7dba;
    cursor: pointer;
}

.confirm-btn {
    color: #7d7d7dba;;
}

.cancel-btn {
    color: #e91e62ba;
    background: #fff;
    box-shadow: 0px 0px 1px 2px #e91e62ba;
}

.confirm-btn:hover {
    color: #fff!important;
    background: #505050ba;
    box-shadow: 0px 0px 1px 0px #505050ba;
}

.cancel-btn:hover {
    color: #fff;
    background: #e91e62ba;
    box-shadow: 0px 0px 1px 2px #e91e62ba;
}

.task-completed {
    border-left-color: #29b65f;
    background: #effff5ab;
}

.task-low-priority {
    border-left-color: #ffefb8;
}

.task-med-priority {
    border-left-color: #ffa970;
}

.task-high-priority {
    border-left-color: #ff6767;
}

.task-title {
    color: #313131;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    line-height: 34px;
    margin: 0;
    padding-left: 1em;
    width: 70%;
    display: inline-block;
    vertical-align: middle;
}

.task-title-calendar-icon {
    width: 17px;
    fill: #6caeda;
    vertical-align: sub;
    margin-right: 10px;
}

.task-container-date {
    font-weight: 500;
    color: #8a8a8a;
    font-size: .85em;
}

.task-date-container {
    padding-top: .3em;
}

.task-icons {
    display: inline-block;
    vertical-align: middle;
}

.svg-icons {
    width: 29px;
    margin: 0 15px;
    fill: #bdbdbd;
    cursor: pointer;
}

.check-svg-completed {
    fill: #29b65f;
}

.check-svg:hover {
    fill: #29b65f;
}

.trash-svg:hover {
    fill: #ee5050;
}

.tasks-filter-container {
    padding: 1em 0 1.5em 3em;
    text-align: left;
    /* border-top: 5px solid #fafafa;
    border-bottom: 5px solid #fafafa; */
}

.tasks-filter {
    display: inline-block;
    padding: .5em .8em;
    margin: 0 1em;
    border-radius: 10px;
    text-transform: uppercase;
    box-shadow: 0px 0px 0px 2px #eee;
    color: #9a9a9a;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

.tasks-filter:hover {
    background: #009688;
    color: white;
    box-shadow: 0px 0px 0px 2px #009688;
}

.tasks-filter::-moz-selection {
    background: none;
}

.tasks-filter::selection {
    background: none;
}

@media only screen and (max-width: 635px) {

    
}


@media only screen and (max-width: 550px) {
    .task-container {
        padding-left: 0;
    }

    .task-title {
        width: 100%;
        box-sizing: border-box;
    }

    .task-icons {
        display: block;
        /* vertical-align: middle; */
        text-align: center;
        padding: 2em 0;
    }

    .task-container {
        padding-left: 0;
        /* flex-wrap: wrap; */
    }

    .task-date-container {
        /* text-align: center; */
    }
}
