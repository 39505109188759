@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');


div::selection, h2::selection, h3::selection, h1::selection, li::selection, input::selection, button::selection, br::selection, span::selection {
    background: none;
}

.main-app {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    grid-template-areas: "projects tasks";
}

.no-content-block {
    padding: 1em;
    margin: 1em;
    box-shadow: 0px 0px 2px 0px #ffcad4d1;
    border-radius: 14px;
    /* letter-spacing: 1px; */
    background: #fff7f86e;
    font-weight: 400;
    color: #9e9e9e;
}

input::placeholder {
    color: #afafaf;
}

.tasks-container {
    /* padding-top: 4em; */
    grid-area: tasks;
    text-align: center;
}

.sign-out-button {
    margin-left: auto;
    position: absolute;
    top: 2em;
    right: 3em;
}

.sign-out-icon {
    width: 30px;
    height: 30px;
    fill: #595959;
    cursor: pointer;
}

.sign-out-icon:hover {
    fill: #e91e63;
}

.show-projects-menu {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .projects-container {
        padding-right: 1.5em;
        grid-area: projects;
        border-right: 7px solid #fafafa;
        text-align: right;
        height: 100vh;
        padding: 2em 2em;
        position: fixed;
        width: 25%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 1024px) {
    .main-app {
        grid-template-columns: 1fr 3fr;
        grid-template-areas: "tasks tasks";
        padding-top: 60px;
    }

    .projects-container {
        display: none;
    }

    .show-projects-menu-container {
        position: fixed;
        width: 100%;
        background: white;
        z-index: 9;
        padding: .7em;
        padding-left: 1.2em;
    }

    .show-projects-menu {
        width: 30px;
        /* position: fixed; */
        z-index: 1000;
        top: 2em;
        left: 1.5em;
        background: white;
        /* box-shadow: 0 0 0px 2px #68bcff; */
        border-radius: 5px;
        fill: #2196F3;
        display: block;
    }

    .show-projects-mobile {
        display: block;
        padding-right: 1.5em;
        width: 100%;
        border-right: none;
        text-align: right;
        padding: 0;
        position: fixed;
        box-sizing: border-box;
        z-index: 3;
    }

    .show-projects-mobile .projects-inner-container {
        width: 100%;
        min-height: 100vh;
        background: white;
        padding-bottom: 5em;
        padding-top: 5em;
        max-height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .show-projects-mobile .add-project-input, .add-project-input-error {
        width: 250px;
        max-width: 71%;
    }

    .sign-out-button {
        margin-left: auto;
        position: fixed;
        top: 1em;
        right: 2em;
        z-index: 9;
    }

    .sign-out-icon {
        width: 25px;
        height: 25px;
        fill: #595959;
        cursor: pointer;
    }
}