.logo-container {
  position: absolute;
}

.hide-logo {
  display: none;
}

.logo {
  width: 20%;
  padding: 10px;
  /* transform: rotate(-9deg); */
}

.logo_lp {
  width: 225px;
  padding: 3em 6em;
}

@media only screen and (max-width: 1024px) {
  .logo-container {
    position: absolute;
    width: 200px;
    text-align: center;
    top: 0;
    z-index: 9;
    left: 0;
    right: 0;
    margin: auto;
  }
  .logo {
    transform: none;
  }
}